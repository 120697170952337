<template>
	<div
		class="full-height flex-column"
	>
		<div
			class="bg-white  full-height overflow-y-auto"
		>
			<div class="full-width ">

				<Search
					:search="search"
					:option="search_option"

					@change="getData"
					@click="getData"
					@toItem="onItem"
					@toExcel="toExcel"
					class="mt-10"
				>
					<select
						v-if="is_admin"
						v-model="search.upper_id"
						slot="add"
						class="pa-5-10 mr-10"
						@change="search.admin_code = ''; getAgencyList()"
					>
						<option value="">소속 총판</option>
						<option
							v-for="(agency, index) in agency_list2"
							:key="'agency_' + index"
							:value="agency.account_id"
						>{{ agency.agency_name }}</option>
					</select>
					<select
						v-if="!is_agency"
						v-model="search.admin_code"
						slot="add"
						class="pa-5-10 mr-10"
						@change="getData"
					>
						<option value="">소속 대리점</option>
						<option
							v-for="(agency, index) in agency_list"
							:key="'agency_' + index"
							:value="agency.account_id"
						>{{ agency.agency_name }}</option>
					</select>
					<select
						slot="add"
						v-model="search.virtual_uid"
						class="mr-10 input-box-inline size-px-12"
						@change="getSearch(1)"
					>
						<option value="">지갑 구분</option>
						<option
							v-for="(virtual, v_index) in items_virtual"
							:key="'virtual_' + v_index"
							:value="virtual.uid"
						>{{ virtual.virtual_name }}</option>
					</select>
				</Search>

				<div
					v-if="items.length > 0"
				>
					<table
						class="mt-10 table table-even"
					>
						<colgroup>
							<col width="80px" />
							<col width="120px" />
							<col width="120px" />
							<col width="120px" />
							<col width="120px" />
							<col width="120px" />
							<col width="auto" />

							<col width="150px" />
							<col width="250px" />
						</colgroup>
						<thead>
						<tr>
							<th>
								<input
									type="checkbox"
								/>
							</th>
							<th>총판</th>
							<th>대리점</th>
							<th>아이디</th>
							<th>이름</th>
							<th>지갑 구분</th>
							<th>지갑 주소</th>

							<th>잔고</th>
							<th>상세정보</th>
						</tr>
						</thead>
						<tbody
							v-if="items.length > 0"
						>
						<tr
							v-for="(item, index) in account_list"
							:key="item.uid"
						>
							<td>{{ item.uid }}</td>
							<td>{{ item.upper_name }}</td>
							<td>{{ item.agency_name }}</td>
							<td>{{ item.member_id }}</td>
							<td>{{ item.member_name }}</td>
							<td>{{ item.virtual_name }}</td>
							<td class="word-break">{{ item.virtual_account }}</td>
							<td>{{ item.balance | makeComma }}원</td>

							<td>

								<button
									v-if="item.is_refresh"
									class="bg-identify pa-5-10 mr-10 color-white"
									@click="postRefresh(item, index)"
								><v-icon class="color-white mdi mdi-refresh size-px-14" /></button>

								<button
									v-if="item.is_send"
									class="bg-identify pa-5-10 mr-10"
									@click="onSend(item)"
								>송금</button>

								<button
									v-if="item.is_return"
									class="bg-identify pa-5-10 mr-10"
									@click="onReturn(item)"
								>회수</button>
							</td>
						</tr>
						</tbody>
					</table>

					<Pagination
						:program="program"
						:align="'center'"
						:options="search"

						@click="getSearch"
					></Pagination>
				</div>

				<Empty
					v-else
				></Empty>
			</div>
		</div>

		<VirtualWithdrawal
			v-if="is_on_widthdrawal_balance"
			:user="user"
			:item_merchant="item_virtual"

			@cancel="is_on_widthdrawal_balance = false"
		></VirtualWithdrawal>

		<PopupLayer
			v-if="is_on_send"
		>
			<template
				v-slot:body
			>
				<div class="bg-white width-480 ma-auto pa-20 radius-20">
					<div class="justify-space-between items-center under-line">
						<h6>지갑 송금</h6>
						<button
							@click="is_on_send = false"
						><v-icon>mdi mdi-close</v-icon></button>
					</div>
					<div class="">
						<table class="table  size-px-14 th-left">
							<col width="150px" />
							<col width="auto" />
							<tbody>
							<tr>
								<th>이름</th>
								<td>{{ item_send.member_name }}</td>
							</tr>
							<tr>
								<th>잔액</th>
								<td>{{ item_send.balance | makeComma }}원</td>
							</tr>
							<tr>
								<th>송금 가능 금액</th>
								<td>{{ invalid_balance | makeComma }} 원</td>
							</tr>
							<tr>
								<th>메모</th>
								<td>
									<input v-model="item.memo" placeholder="메모" class="input-box" maxlength="50"/>
								</td>
							</tr>
							<tr>
								<th>송금 금액</th>
								<td>
									<div class="justify-space-between">
										<div>
											<input v-model="item.send_amount" class="input-box" type="number" :rules="$rules.max(item, 'send_amount', 8)"/>
										</div>
										<button
											class="btn-inline btn-primary"
											:disabled="is_send"

											@click="postSend"
										>송금</button>
									</div>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>
			</template>
		</PopupLayer>

		<PopupLayer
			v-if="is_on_return"
		>
			<template
				v-slot:body
			>
				<div class="bg-white width-480 ma-auto pa-20 radius-20">
					<div class="justify-space-between items-center under-line">
						<h6>잔고 회수</h6>
						<button
							@click="is_on_return = false"
						><v-icon>mdi mdi-close</v-icon></button>
					</div>
					<div class="">
						<table class="table  size-px-14 th-left">
							<col width="150px" />
							<col width="auto" />
							<tbody>
							<tr>
								<th>이름</th>
								<td>{{ item_return.member_name }}</td>
							</tr>
							<tr>
								<th>잔액</th>
								<td>{{ item_return.balance | makeComma }}원</td>
							</tr>
							<tr>
								<th>메모</th>
								<td>
									<input v-model="item.memo" placeholder="메모" class="input-box" maxlength="50"/>
								</td>
							</tr>
							<tr>
								<th>회수 금액</th>
								<td>
									<div class="justify-space-between">
										<div>
											<input v-model="item.return_amount" class="input-box" type="number" :rules="$rules.max(item, 'return_amount', 8)"/>
										</div>
										<button
											class="btn-inline btn-primary"
											:disabled="is_return"

											@click="postReturn"
										>회수</button>
									</div>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>
			</template>
		</PopupLayer>

		<Excel
			v-if="is_excel"
			:excel_data="excel_data"
			:date="date"

			@close="is_excel = false"
		></Excel>
	</div>
</template>

<script>

import Pagination from "../../components/Pagination";
import Search from "../Layout/Search";
import Empty from "@/view/Layout/Empty";
import PopupLayer from "../Layout/PopupLayer";
import VirtualWithdrawal from "@/view/Virtual/VirtualWithdrawal";
import Excel from "@/components/Excel";

export default {
	name: 'MemberWallet'
	, props: ['user', 'codes', 'virtual_info', 'auth', 'date']
	, components: {Excel, VirtualWithdrawal, PopupLayer, Search, Pagination, Empty}
	, data: function(){
		return {
			program: {
				name: '회원 지갑 목록'
				, top: true
				, title: true
				, bottom: false
			}
			, virtual_uid: this.$route.params.idx
			, search: {
				virtual_uid: ''
				, virtual_status: ''
				, virtual_able: ''
				, list_cnt: 10
				, search_type: 'member_name'
				, search_value: ''
				, is_use: ''
				, total_count: 0
				, page: 1
				, tCnt: 0
				, upper_id: ''
				, admin_code: ''
			}
			,search_option:{

				is_cnt: true
				, is_excel: true
				,cnt: 0
				,tCnt: 0
				,search_type: [
					{ name: '아이디', column: 'member_id'}
					, { name: '이름', column: 'member_name'}
				]
				,select: [
					{ name: '사용 여부', column: 'is_use', items: [
							{ name: '사용가능', column: '1'}
							,{ name: '사용불가', column: '0'}
						]
					}
				]
			}
			, item_virtual: {

			}
			, items: []
			, item_detail: {}
			, is_on_widthdrawal_balance: false
			, item_send: {

			}
			, is_on_send: false
			, send_amount: ''
			, is_on_send_confirm: false
			, item: {
				send_amount: ''
				, return_amount: ''
				, memo: ''
			}
			, item_return: {

			}
			, is_on_return: false
			, items_virtual: ''
			,  	is_excel: false
			,excel_data: {
				name: '회원 지갑 목록'
				,header: [
					{ key: 0, name: '총판', column: 'upper_name'}
					, { key: 0, name: '대리점', column: 'agency_name'}
					,{ key: 0, name: '아이디', column: 'member_id'}
					,{ key: 0, name: '이름', column: 'member_name'}
					,{ key: 0, name: '지갑구분', column: 'virtual_name'}
					,{ key: 0, name: '지갑주소', column: 'virtual_account'}
					,{ key: 0, name: '잔고', column: 'balance'}
				]
				,content: null
			}
			, agency_list: []
			, agency_list2: []
		}
	}
	, computed: {
		account_list: function(){
			return this.items.filter( (item) => {

				if(item.account_id != this.user.account_id && item.wallet_type == 'user'){
					item.is_send = true
				}
				if(this.auth.is_admin && item.account_id != this.user.account_id && item.wallet_type == 'user'){
					item.is_return = true
				}
				if( item.account_id != this.user.account_id && item.virtual_account && item.wallet_type == 'user'){
					item.is_refresh = true
				}
				return item
			})
		}
		, is_account: function(){
			let t = false
			if(this.mode == 'account'){
				t = true
			}
			return t
		}
		, is_send: function(){
			let t = true

			if(this.item.send_amount > 0 && Number(this.invalid_balance) >= Number(this.item.send_amount)){

				t = false
			}
			return t
		}
		, is_return: function(){
			let t = true
			if(this.item.return_amount > 0){
				t = false
			}
			return t
		}
		, invalid_balance: function(){
			let t = 0
			if(this.user && this.user.balance){
				t = this.user.balance
			}
			return t
		}
		,is_admin: function(){
			if(this.user.admin_type == 'admin'){
				return true
			}else{
				return false
			}
		}
		,is_agency: function(){
			if(this.user.admin_type == 'agency'){
				return true
			}else{
				return false
			}
		}
		,is_distributor: function(){
			if(this.user.admin_type == 'distributor'){
				return true
			}else{
				return false
			}
		}
	}
	, methods: {

		getVirtualInfo: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: 'admin/getVirtualFeeMember'
					,data: {
						virtual_uid: this.virtual_uid
					}
				})

				if(result.success){
					this.item_virtual = result.data
					if(this.item_virtual.virtual_info){
						let d =  JSON.parse(this.item_virtual.virtual_info)
						for(const[key, value] of Object.entries(d)){
							this.$set(this.item_virtual, key, value)
						}
					}
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getSearch: function(page){
			if(!page){
				page = 1
			}
			this.search.page = page
			this.getData()
		}
		, onItem: function(){
			this.item_info = { }
			this.is_modal_item = true
		}
		, toList: function(item){
			this.$bus.$emit('to', { name: 'VirtualAccountList', params: { idx: item.uid }})
		}
		, getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: 'management/getWalletList'
					,data: {
						virtual_uid: this.search.virtual_uid
						, page: this.search.page
						, list_cnt: this.search.list_cnt
						, search_type: this.search.search_type
						, search_value: this.search.search_value
						, admin_code: this.search.admin_code
						, upper_id: this.search.upper_id
					}
				})

				if(result.success){
					this.items = result.data.result
					this.search.tCnt = result.data.tCnt
					this.search_option.tCnt = result.data.tCnt
					this.$set(this.search, 'total_count', result.data.tCnt)
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e })
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onSend: function(item){
			this.item_send = item
			this.is_on_send = true
		}
		, onReturn: function(item){
			this.item_return = item
			this.is_on_return = true
		}
		, postSend: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					,url: 'management/postSendMember'
					,data: {
						virtual_uid: this.search.virtual_uid
						, member_id: this.item_send.member_id
						, amount: this.item.send_amount
						, memo: this.item.memo
					}
				})

				if(result.success){
					this.is_on_send = false
					await this.getData()
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e })
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, postReturn: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					,url: 'management/postReturnMember'
					,data: {
						virtual_uid: this.search.virtual_uid
						, member_id: this.item_return.account_id
						, amount: this.item.return_amount
						, memo: this.item.memo
					}
				})

				if(result.success){
					this.is_on_return = false
					await this.getData()
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e })
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postRefresh: async function(item, index){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					,url: 'management/postRefresh'
					,data: {
						virtual_uid: this.search.virtual_uid
						, shop_uid: item.shop_uid
						, wallet_uid: item.uid
						, account_id: item.account_id
					}
				})

				if(result.success){
					this.items[index].balance = result.data
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e })
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getVirtualList: async function() {
			try {
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					, url: 'management/getVirtualList'
					, data: {
						agency_type: this.item.agency_type
					}
				})
				if (result.success) {
					this.items_virtual = result.data
					this.search.virtual_uid = this.items_virtual[0].uid
				} else {
					this.$bus.$emit('notify', {type: 'error', message: result.message})
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.$bus.$emit('on', false)
			}
		}
		,toExcel: function(){
			this.excel_data.content = this.items
			this.is_excel = true
		}
		,getAgencyList: async function() {
			if(this.user.role != this.codes.type_code_admin && this.user.route != this.codes.type_code_distributor){
				// return false
			}
			try{
				const result = await this.$request.init({
					method: 'get'
					,url: 'management/getAgencyList'
					,data: {
						agency_type: 'A001003'
						, upper_id: this.search.upper_id
						, type: 'n'
						, list_cnt: 100
					}
				})

				if(result.success){
					this.agency_list = result.data.result
					//this.search_option.select[0].items = this.select_option_agency
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
			}
		}
		,getAgencyList2: async function() {
			if(this.user.role != this.codes.type_code_admin && this.user.route != this.codes.type_code_distributor){
				// return false
			}
			try{
				const result = await this.$request.init({
					method: 'get'
					,url: 'management/getAgencyList'
					,data: {
						agency_type: 'A001002'
						, list_cnt: 100
					}
				})

				if(result.success){
					this.agency_list2 = result.data.result
					//this.search_option.select[0].items = this.select_option_agency
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
			}
		}
	}
	, async created() {
		this.$emit('onLoad', this.program)
		await this.getVirtualList()
		await this.getAgencyList2()
		await this.getAgencyList()
		await this.getData()
	}
}
</script>

<style>
.tab h6 { border-right: 1px solid white; cursor: pointer}
</style>